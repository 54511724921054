import React, { Fragment, useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Dropdown } from './common/Dropdown';

interface SelectDateProps {
  setStartDate: React.SetStateAction<any> | ((val: any) => void);
  setEndDate: React.SetStateAction<any> | ((val: any) => void);
  page?: string;
  nowTime?: string;
  maxPeriod: Period;
}

interface SelectDateV2Props extends SelectDateProps {
  maximumRange: string;
}

export enum Period {
  TODAY = '1',
  YESTERDAY = '2',
  THIS_WEEK = '3',
  PREV_WEEK = '4',
  MONTH = '5',
  PREV_MONTH = '6',
  YEAR = '7',
}

export const PeriodAsDays = [1, 2, 7, 14, 31, 62, 365];

const StyledSelectDate = styled.select`
  padding: 9px 12px;
  @media screen and (max-width: 480px) {
    font-size: 0.75rem;
    padding: 7px 2px;
  }
`;

const SelectDate = ({ setStartDate, setEndDate, nowTime, page, maxPeriod }: SelectDateProps) => {
  const month =
    page === 'ticket'
      ? [
          '어제',
          '이번 주',
          '저번 주',
          '이번 달',
          '저번 달',
          '1년',
          // "전체"
        ]
      : [
          '어제',
          '이번 주',
          '저번 주',
          '이번 달',
          '저번 달',
          '1년 전',
          // "전체"
        ];
  const handleDate = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    if (target.value === '어제') {
      setStartDate(moment(new Date()).subtract(1, 'd').toDate());
      setEndDate(moment(new Date()).subtract(1, 'd').toDate());
    } else if (target.value === '이번 주') {
      setStartDate(moment(new Date()).isoWeekday(1).startOf('isoWeek').toDate());
      setEndDate(moment(new Date()).isoWeekday(1).endOf('isoWeek').toDate());
    } else if (target.value === '저번 주') {
      setStartDate(moment(new Date()).subtract(1, 'w').isoWeekday(1).startOf('isoWeek').toDate());
      setEndDate(moment(new Date()).subtract(1, 'w').isoWeekday(1).endOf('isoWeek').toDate());
    } else if (target.value === '이번 달') {
      setStartDate(moment(new Date()).startOf('month').toDate());
      // setEndDate(new Date());
      setEndDate(moment(new Date()).endOf('month').toDate());
    } else if (target.value === '저번 달') {
      setStartDate(moment(new Date()).subtract(1, 'M').startOf('month').toDate());
      setEndDate(moment(new Date()).startOf('month').subtract(1, 'd').toDate());
    } else if (target.value === '1년 전') {
      setStartDate(moment(new Date()).subtract(1, 'y').toDate());
      setEndDate(new Date());
    } else if (target.value === '오늘') {
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (target.value === '1년') {
      setStartDate(moment(new Date()).subtract(1, 'y').toDate());
      setEndDate(moment(new Date()).add(1, 'y').toDate());
    }
  };

  return (
    <Dropdown>
      <StyledSelectDate onChange={handleDate} defaultValue={nowTime ? nowTime : '오늘'}>
        {!nowTime && (
          <>
            <option value="오늘" defaultChecked>
              오늘
            </option>
            {month.slice(0, maxPeriod ? parseInt(maxPeriod) : month.length).map((li) => (
              <option key={li} value={li}>
                {li}
              </option>
            ))}
          </>
        )}
        {nowTime && (
          <>
            <option value="오늘">오늘</option>
            {month.map((li) => (
              <Fragment key={li}>
                {nowTime === li ? (
                  <option defaultChecked key={li} value={li}>
                    {li}
                  </option>
                ) : (
                  <option key={li} value={li}>
                    {li}
                  </option>
                )}
              </Fragment>
            ))}
          </>
        )}
      </StyledSelectDate>
    </Dropdown>
  );
};

export enum SelectDateMaxRage {}

export const SelectDateV2 = ({
  setStartDate,
  setEndDate,
  nowTime,
  maximumRange,
}: SelectDateV2Props) => {
  const Month = useMemo(() => {
    const base = ['어제', '이번 주', '저번 주', '이번 달', '최근 1개월', '저번 달', '1년'];
    try {
      if (!maximumRange) return base;
      const findIndex = base.findIndex((range) => range === maximumRange);
      return base.slice(0, findIndex + 1);
    } catch (error) {
      return base;
    }
  }, [maximumRange]);

  const handleDate = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    if (!target || !target.value) return;

    if (target.value === '어제') {
      setStartDate(moment(new Date()).subtract(1, 'd').toDate());
      setEndDate(moment(new Date()).subtract(1, 'd').toDate());
      return;
    }

    if (target.value === '이번 주') {
      setStartDate(moment(new Date()).isoWeekday(1).startOf('isoWeek').toDate());
      setEndDate(moment(new Date()).isoWeekday(1).endOf('isoWeek').toDate());
      return;
    }

    if (target.value === '이번 달') {
      setStartDate(moment(new Date()).startOf('month').toDate());
      setEndDate(moment(new Date()).endOf('month').toDate());
      return;
    }

    if (target.value === '최근 1개월') {
      setStartDate(moment(new Date()).subtract(1, 'M').toDate());
      setEndDate(moment(new Date()).toDate());
      return;
    }

    if (target.value === '저번 달') {
      setStartDate(moment(new Date()).subtract(1, 'M').startOf('month').toDate());
      setEndDate(moment(new Date()).startOf('month').subtract(1, 'd').toDate());
      return;
    }

    if (target.value === '1년 전') {
      setStartDate(moment(new Date()).subtract(1, 'y').toDate());
      setEndDate(new Date());
      return;
    }

    if (target.value === '오늘') {
      setStartDate(new Date());
      setEndDate(new Date());
      return;
    }

    if (target.value === '1년') {
      setStartDate(moment(new Date()).subtract(1, 'y').toDate());
      setEndDate(moment(new Date()).add(1, 'y').toDate());
      return;
    }
  };

  return (
    <Dropdown>
      <StyledSelectDate onChange={handleDate} defaultValue={nowTime ? nowTime : '오늘'}>
        {!nowTime && (
          <>
            <option value="오늘" defaultChecked>
              오늘
            </option>
            {Month.map((li) => (
              <option key={li} value={li}>
                {li}
              </option>
            ))}
          </>
        )}
        {nowTime && (
          <>
            <option value="오늘">오늘</option>
            {Month.map((li) => (
              <Fragment key={li}>
                {nowTime === li ? (
                  <option defaultChecked key={li} value={li}>
                    {li}
                  </option>
                ) : (
                  <option key={li} value={li}>
                    {li}
                  </option>
                )}
              </Fragment>
            ))}
          </>
        )}
      </StyledSelectDate>
    </Dropdown>
  );
};

export default SelectDate;
