import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import styled, { css } from 'styled-components';
import moment from 'moment';
import {
  getLockerInfoList,
  getMapViewList,
  getMerchantInfo,
  getSeminarItemList,
  merchantId_api,
} from '../lib/api';
import 'moment/locale/ko';
import ChoiceModal from '../components/modal/choiceModal';
import { secondToHour } from './ticketList';
import { LockerS, LockersArray, LockerView } from './lockerChangeV2';
import ChangeLockerModal from '../components/modal/lockerChangeModal';
import configDB from '../data/customizer/config';
import { Maximize2, Minimize2 } from 'react-feather';
import SeatBlockModal from '../components/modal/seatBlockModal';
import PageContainer from '../components/common/PageContainer';
import { SeatLegend, SeatLegendItem } from '../components/SeatLegend';
import theme from '../theme';
import { RadioBtn, RadioLabel } from '../components/commonStyle';
import Format from '../util/formatter';
import {
  LockerViewV1,
  LockerViewV2,
  MapViewLocker,
  MapViewLockerV1,
  MapViewSeat,
  SeatLevel,
} from '../components/common/Seat';
import useMousePosition from '../lib/hooks/useMousePosition';
import {
  getSeatRemainRestTime,
  getSeatRemainUsingTime,
  getSeatTicketTypeString,
  getSeatTypeString,
} from '../util/seat';
import useWindow from '../lib/hooks/useWindow';
import NotFoundContainer from '../components/common/NotFoundContainer';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const getSeatColor = (type, seatType, ticketType, status) => {
  if (status === 'block') return theme.unavailableSeat;
  if (type === 'seminar') return theme.seminarSeat;
  if (status === 'idle' && seatType === 0) return theme.idleSeat;
  if (status === 'idle' && seatType === 1) return theme.idleSeat;
  if (status === 'using' || status === 'rest') {
    if (seatType === 1) return theme.fixedSeatWithPeriodicTicket;
    if (ticketType === 1) return theme.normalSeatWithDisposableTicket;
    if (ticketType === 2) return theme.normalSeatWithPeriodicTicket;
    if (ticketType === 3) return theme.normalSeatWithTimeBasedTicket;
  }

  return theme.normalSeat;
};
const getSeatBorderColor = (type, seatType, ticketType, status) => {
  if (status === 'block') return theme.unavailableSeat;
  if (type === 'seminar') return theme.seminarSeat;
  if (status === 'idle' && seatType === 0) return theme.neutralGray[300];
  if (status === 'idle' && seatType === 1) return theme.fixedSeatWithPeriodicTicket;

  if (status === 'using' || status === 'rest') {
    if (seatType === 1) return theme.fixedSeatWithPeriodicTicket;
    if (ticketType === 1) return theme.normalSeatWithDisposableTicket;
    if (ticketType === 2) return theme.normalSeatWithPeriodicTicket;
    if (ticketType === 3) return theme.normalSeatWithTimeBasedTicket;
  }

  return theme.normalSeat;
};

const getSeatTextColor = (status) => {
  if (status === 'block') return theme.neutralGray[500];
  return theme.normalSeat;

  // if (status === 'block') return theme.neutralGray[400];
  // if (status === 'using') return theme.neutralGray[100];
  // return theme.indigo;
};

const SeatLegendData = [
  { text: '빈 자유석', seatColor: theme.white, borderColor: theme.neutralGray[300] },
  { text: '빈 고정석', seatColor: theme.white, borderColor: theme.fixedSeatWithPeriodicTicket },
  { text: '빈 세미나실', seatColor: theme.white, borderColor: theme.seminarSeat },
  { text: '세미나실', seatColor: theme.seminarSeat, borderColor: theme.seminarSeat },
  {
    text: '자유석-일회권',
    seatColor: theme.normalSeatWithDisposableTicket,
    borderColor: theme.normalSeatWithDisposableTicket,
  },
  {
    text: '자유석-기간권',
    seatColor: theme.normalSeatWithPeriodicTicket,
    borderColor: theme.normalSeatWithPeriodicTicket,
  },
  {
    text: '자유석-시간정액권',
    seatColor: theme.normalSeatWithTimeBasedTicket,
    borderColor: theme.normalSeatWithTimeBasedTicket,
  },
  {
    text: '고정석-기간권',
    seatColor: theme.fixedSeatWithPeriodicTicket,
    borderColor: theme.fixedSeatWithPeriodicTicket,
  },
  {
    text: '사용 불가 좌석',
    seatColor: theme.neutralGray[300],
    borderColor: theme.neutralGray[300],
  },
];

const LockerLegendData = [
  { text: '빈 사물함', seatColor: theme.white, borderColor: theme.neutralGray[400] },
  { text: '점유된 사물함', seatColor: theme.occupiedSeat, borderColor: theme.occupiedSeat },
  {
    text: '이용 불가 처리한 사물함',
    seatColor: theme.neutralGray[400],
    borderColor: theme.neutralGray[400],
  },
  {
    text: '기간이 3일 미만으로 남은 사물함',
    seatColor: theme.warning,
    borderColor: theme.warning,
  },
];

export const ToggleBtn = styled.label`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
  margin-top: 0.5rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .onoff-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background-color: #ccc;
    transition: 0.2s;
    width: 46px;
  }
  .onoff-switch:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: #fff;
    transition: 0.2s;
    border-radius: 20px;
  }
  input:checked + .onoff-switch {
    background-color: ${primary};
  }
  input:checked + .onoff-switch:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(20px);
  }

  .disabled {
    cursor: not-allowed !important;
  }

  .tootip-text {
    position: absolute;
    width: 10vw;
  }
`;

export const TabList = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  /* margin: 10px 0; */
  width: 100%;
  border-bottom: solid 1px #ccc;
`;

export const TabButton = styled.button`
  padding: 0 1rem;
  height: 60px;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  border: none;
  color: ${(props) => (props.selected ? '#222' : '#A9AFC0')};
  border-bottom: solid 2px ${(props) => (props.selected ? primary : '#fff')};
  /* @media screen and (max-width: 480px) {
    height: 45px;
    margin-right: 20px;
  } */
`;

export const PossibleColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 50px;
  margin-bottom: 14px;
  flex: 1;
  padding: 5px;
  text-align: center;
  background-color: ${(props) => (props.color ? props.color : '#222222')};
  border: solid 1px ${(props) => (props.color ? props.color : '#222222')};
  color: #fff;
  border-radius: 5px;
  > p {
    font-size: 16px;
  }

  @media screen and (max-width: 805px) {
    display: none;
  }
`;
export const PossibleColorL = styled(PossibleColor)`
  background-color: ${(props) => (props.isUse ? '#888ea5' : '#fff')};
  border: 1px solid ${(props) => (props.isUse ? '#888ea5' : '#222')};

  color: ${(props) => (props.isUse ? '#fff' : '#222')};
`;
export const SeatView = styled.div`
  /* width: 920px; */

  background-color: ${({ theme }) => theme.neutralGray[500]};
  border-radius: 4px;
  position: relative;
  /* margin: 0 auto; */
  width: 100%;
  max-width: 1440px;
  height: 1444px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4cbd6;
    border-radius: 1vw;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 1vw;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 805px) {
    width: ${(props) => (props.isMin ? `calc(920px * ${props.ratio})` : ` 100%`)};
    height: ${(props) => (props.isMin ? `calc(1444px * ${props.ratio})` : ` 1444px`)};
    margin: 0 auto;
    ::-webkit-scrollbar {
      width: 0vw;
      height: 0;
    }
  }
`;

export const SeatDefaultArea = styled.div`
  position: absolute;

  width: 1080px;
  height: 1444px;

  border: 2px solid ${({ theme }) => theme.red};
`;

const HoverInfo = styled.span`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  padding: 3px;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  left: 3px;
  z-index: 1;
  background-color: ${(props) => props.used};
  p {
    font-size: 9px;
    letter-spacing: -0.35px;
    line-height: 1.7;
  }
`;
export const Seat = styled.div`
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  position: absolute;
  color: ${({ status }) => getSeatTextColor(status)};
  background: ${({ type, seatType, ticketType, status }) =>
    getSeatColor(type, seatType, ticketType, status)};
  border: ${({ type, seatType, ticketType, status }) =>
    `2px solid ${getSeatBorderColor(type, seatType, ticketType, status)}`};

  width: 4.5rem;
  height: 3rem;
  padding: 0;
  /* display: flex;
  flex-direction: column; */

  display: grid;
  grid-template-rows: repeat(3, 1fr);

  opacity: ${(props) => (props.rest ? 0.8 : 1)};
  /* p {
    grid-row: 2;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    line-height: 100%;
  } */

  div {
    margin: 0;
    font-weight: 600;
    text-align: center;
    line-height: 100%;
    letter-spacing: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .seatNumber {
    grid-row: 2;
    margin-bottom: 0;
    font-size: 1.125rem;
  }
  .seatInfoAndRemainedTime,
  .userInfo {
    height: 2.75rem;
    grid-row: 1 span 3;
    vertical-align: middle;
    font-size: 0.75rem;
  }

  .seatType {
    font-size: 1.4vw;
  }

  &:hover ${HoverInfo} {
    display: flex;
    background-color: ${(props) => props.used};
  }
`;
// const LockerS1 = styled(LockerS)`
//   position: relative;
//   &:hover ${HoverInfo} {
//     display: flex;
//     flex-direction: column;
//   }
// `;

export const SeatArrayS = styled.div`
  position: absolute;
  width: 920px;
  height: 1444px;
  display: flex;
  @media screen and (max-width: 805px) {
    left: 0%;
    transform: translate(-0%, 0);
    width: ${(props) => (props.isMin ? `calc(920px * ${props.ratio})` : ``)};
    height: ${(props) => (props.isMin ? `calc(1444px * ${props.ratio})` : ` 1444px`)};
  }
`;

export const Wall = styled.div`
  top: ${(props) => (props.isMin ? `calc(${props.top}px * ${props.ratio})` : `${props.top}px`)};
  left: ${(props) => (props.isMin ? `calc(${props.left}px * ${props.ratio})` : `${props.left}px`)};
  width: ${(props) =>
    props.isMin ? `calc(${props.width}px * ${props.ratio})` : `${props.width}px`};
  height: ${(props) =>
    props.isMin ? `calc(${props.height}px * ${props.ratio})` : `${props.height}px`};
  position: absolute;
  background-color: #fff;
`;
export const Text = styled.div`
  position: absolute;
  top: ${(props) => (props.isMin ? `calc(${props.top}px * ${props.ratio})` : `${props.top}px`)};
  left: ${(props) => (props.isMin ? `calc(${props.left}px * ${props.ratio})` : `${props.left}px`)};
  color: #fff;
  font-size: ${(props) =>
    props.isMin ? `calc(${props.size}px * ${props.ratio})` : `${props.size}px`};
`;

export const SizeBtn = styled.button`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  bottom: 5%;
  right: 2%;
  z-index: 999;
  border-radius: 50%;
  background-color: ${primary};
  border: none;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  > svg {
    color: #ffffff;
  }
  @media screen and (min-width: 805px) {
    display: none;
  }
`;

const HorizontalContentArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`;

const SeatListInfo = ({ setIsLoading }) => {
  const [merchantId, setMerchantId] = useState(0);
  const [ratio, setRatio] = useState(1);
  const [usingLockerV2, setUsingLockerV2] = useState(false); // V2 키오스크에서 사물함을 설정 여부

  const [tabMenu, setTabMenu] = useState('seat');
  const [lists, setLists] = useState([]);
  const [lockerList, setLockerList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [seatData, setSeatData] = useState();

  const [isLockerChange, setIsLockerChange] = useState(false);
  const [selectedLocker, setSelectedLocker] = useState();
  const [toggle, setToggle] = useState(false);
  const [walls, setWalls] = useState([]);
  const [texts, setTexts] = useState([]);
  const [level, setLevels] = useState(false);
  const [isMinimal, setIsMinimal] = useState(true);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [seminarArr, setSeminarArr] = useState(null);
  const [multiNum, setMultiNum] = useState(null);
  const [floorNum, setFloorNum] = useState(0);

  const [selectedMode, setSelectedMode] = useState('seatNumber');

  const windowSize = useWindow();

  useEffect(() => {
    let getId = getMerchantId();
    return () => clearTimeout(getId);
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      setMerchantId(data.id);

      const multiNumData = await getMerchantInfo(data.id);
      if (multiNumData && multiNumData.status === 200) {
        if (multiNumData.data[0].multiKioskFloor) {
          setMultiNum(multiNumData.data[0].multiKioskFloor);
          setFloorNum(1);
          getSeatListApi(data.id, 1);
          getLockerListApi(data.id, 1);
        } else {
          getSeatListApi(data.id, null);
          getLockerListApi(data.id, null);
        }
      }
      const res = await getSeminarItemList(
        data.id,
        moment(new Date()).format('YYYYMMDD000000'),
        moment(new Date()).format('YYYYMMDD235959'),
      );
      if (res.data?.length > 0) {
        const filter = res.data.filter((li) =>
          moment(new Date()).isBetween(moment(li.startDate), moment(li.endDate)),
        );

        setSeminarArr(filter);
      }
    }
  };
  const getLockerListApi = async (merchantId, floorNum) => {
    const { data } = await getLockerInfoList(merchantId);
    const sum = data.reduce(
      (previous, current) => previous + current.pos_top + current.pos_left,
      0,
    );
    if (sum > 0) setUsingLockerV2(true);
    if (floorNum) {
      setLockerList(
        data.filter((item) => (!item.floor && floorNum === 1) || item.floor === floorNum),
      );
    } else {
      setLockerList(data);
    }
  };
  const getSeatListApi = async (merchantId, floorNum) => {
    try {
      setIsLoading(true);
      const res = await getMapViewList(merchantId);

      if (res.status === 200) {
        if (floorNum) {
          setLists(
            res.data.filter(
              (item) =>
                (item.type === 'seat' || item.type === 'seminar') &&
                ((!item.floor && floorNum === 1) || item.floor === floorNum),
            ),
          );
          setWalls(
            res.data.filter(
              (li) =>
                li.type === 'wall' && ((!li.floor && floorNum === 1) || li.floor === floorNum),
            ),
          );
          setTexts(
            res.data.filter(
              (li) =>
                li.type === 'text' && ((!li.floor && floorNum === 1) || li.floor === floorNum),
            ),
          );
        } else {
          setLists(res.data.filter((item) => item.type === 'seat' || item.type === 'seminar'));
          setWalls(res.data.filter((li) => li.type === 'wall'));
          setTexts(res.data.filter((li) => li.type === 'text'));
        }
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const secondsToDayHourFormat = (seconds) => {
    // 1일, 1시간, 1분을 초 단위로 계산
    const oneDayInSeconds = 24 * 60 * 60;
    const oneHourInSeconds = 60 * 60;
    const oneMinuteInSeconds = 60;
    console.log(seconds);
    // 일, 시간, 분, 초 계산
    const days = Math.floor(seconds / oneDayInSeconds);
    seconds %= oneDayInSeconds;

    const hours = Math.floor(seconds / oneHourInSeconds);
    seconds %= oneHourInSeconds;

    const minutes = Math.floor(seconds / oneMinuteInSeconds);
    seconds %= oneMinuteInSeconds;

    const secs = seconds;

    // 남은 시간이 1일 이상이면 '일' 단위 포함, 그렇지 않으면 '일' 단위 제외
    if (days > 0)
      return `${days}일 ${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0',
      )}:${String(secs).padStart(2, '0')}`;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      secs,
    ).padStart(2, '0')}`;
  };

  // const diffTime = (t1) => {
  //   const duration = moment.duration(moment(t1).diff(new Date()));
  //   const durationAsMiliseconds = duration.asMilliseconds();
  //   const day = duration.days();
  //   const hours = Math.abs(day > 0 ? day * 24 + duration.hours() : duration.hours());
  //   const minutes = Math.abs(duration.minutes());
  //   const seconds = Math.abs(duration.seconds());
  //   return `${`${hours}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(
  //     2,
  //     '0',
  //   )}${durationAsMiliseconds < 0 ? '<br />지남' : '<br />남음'}`;
  // };
  // const diffTime1 = (lastInData, remainTime) => {
  //   const nowTime = moment();
  //   const lastIn = moment(lastInData);
  //   const remainTimeData = moment.duration(nowTime.diff(lastIn)).asSeconds();
  //   return remainTime - Math.ceil(remainTimeData);
  // };

  // const differencialOfDate = (startDate, endDate) => {
  //   const start = moment(startDate);
  //   const end = moment(endDate);
  //   const remainTimeData = moment.duration(end.diff(start)).asDays();

  //   return Math.floor(remainTimeData);
  // };

  // const diffTimeToRest = (t2) => {
  //   const day = moment.duration(moment(new Date()).diff(t2)).days();
  //   const hours =
  //     day > 0
  //       ? day * 24 + moment.duration(moment(new Date()).diff(t2)).hours()
  //       : moment.duration(moment(new Date()).diff(t2)).hours();
  //   const minutes = moment.duration(moment(new Date()).diff(t2)).minutes();
  //   const seconds = moment.duration(moment(new Date()).diff(t2)).seconds();
  //   return (
  //     (hours > 0 && hours < 10 ? '0' + hours : hours) +
  //     ' : ' +
  //     (minutes > -1 && minutes < 10 ? '0' + minutes : minutes) +
  //     ' : ' +
  //     (seconds > -1 && seconds < 10 ? '0' + seconds : seconds)
  //   );
  // };
  const diffTimeToLocker = (t1) => {
    const day = moment.duration(moment(t1).diff(moment(new Date()))).asDays();

    return Math.floor(day + 1);
  };
  const choiceList = () => {
    setIsModal(!isModal);
  };

  const blockModal = (seat) => {
    setIsBlockModal(!isBlockModal);
    setSeatData(seat);
  };
  const showModal = (seat) => {
    setSeatData(seat);
    setIsModal(true);
  };
  const handleSelectLocker = (locker) => {
    if (locker.status !== 'using') return;
    setIsLockerChange(!isLockerChange);
    setSelectedLocker(locker);
  };

  useEffect(() => {
    if (!merchantId) return;
    getSeatListApi(merchantId, floorNum);
    getLockerListApi(merchantId, floorNum);
  }, [merchantId, floorNum]);

  useEffect(() => {
    if (!windowSize) return;
    setRatio((windowSize.width * 0.8) / 920);
    if (windowSize.width > 804) setIsMinimal(false);
  }, [windowSize]);

  return (
    <Fragment>
      {isModal && (
        <ChoiceModal
          isOpen={isModal}
          seatData={seatData}
          onClose={choiceList}
          setLists={setLists}
          lists={lists}
          floorNum={floorNum}
        />
      )}
      {isBlockModal && (
        <SeatBlockModal
          isOpen={isBlockModal}
          seatData={seatData}
          onClose={blockModal}
          setLists={setLists}
          lists={lists}
          floorNum={floorNum}
          fetch={() => {
            getSeatListApi(merchantId, floorNum);
            setIsBlockModal(false);
          }}
        />
      )}
      {isLockerChange && (
        <ChangeLockerModal
          isOpen={isLockerChange}
          onClose={() => setIsLockerChange(!isLockerChange)}
          selected={selectedLocker}
          lists={lockerList}
        />
      )}
      {/* <SeatUserInfoHoverArea x={x} y={y} /> */}
      <Breadcrumb parent="좌석 및 사물함" title="맵뷰어" />
      <PageContainer title="맵뷰어">
        {/* 로딩 컴포넌트 */}

        <TabList>
          <TabButton selected={tabMenu === 'seat'} onClick={() => setTabMenu('seat')}>
            이용권
          </TabButton>
          <TabButton selected={tabMenu === 'locker'} onClick={() => setTabMenu('locker')}>
            사물함
          </TabButton>
        </TabList>
        <SizeBtn show={true} onClick={() => setIsMinimal(!isMinimal)}>
          {!isMinimal ? <Minimize2 /> : <Maximize2 />}
        </SizeBtn>

        {tabMenu === 'seat' ? (
          <>
            <HorizontalContentArea>
              <RadioLabel>
                <RadioBtn
                  checked={selectedMode === 'seatNumber'}
                  onClick={() => setSelectedMode('seatNumber')}
                />
                좌석 번호 표시
              </RadioLabel>
              <RadioLabel>
                <RadioBtn
                  checked={selectedMode === 'seatInfoAndRemainedTime'}
                  onClick={() => setSelectedMode('seatInfoAndRemainedTime')}
                />
                좌석 정보 및 남은 시간 표시
              </RadioLabel>
              <RadioLabel>
                <RadioBtn
                  checked={selectedMode === 'userInfo'}
                  onClick={() => setSelectedMode('userInfo')}
                />
                회원 정보 표시
              </RadioLabel>
            </HorizontalContentArea>
            {/* <ToggleContainer isSeat>
              사용자 정보 보기
              <ToggleBtn>
                <input
                  type="checkbox"
                  value={toggle || ''}
                  checked={toggle}
                  onChange={() => setToggle(!toggle)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            <ToggleContainer isSeat>
              좌석 등급보기
              <ToggleBtn>
                <input
                  type="checkbox"
                  value={level || ''}
                  checked={level}
                  onChange={() => setLevels(!level)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            */}

            {multiNum && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {[...Array(multiNum)].map((item, index) => (
                  <TabButton
                    selected={floorNum === index + 1}
                    onClick={() => setFloorNum(index + 1)}
                    value={index + 1}>
                    {index + 1}층
                  </TabButton>
                ))}
              </div>
            )}

            <SeatLegend>
              {SeatLegendData.map((legend) => (
                <SeatLegendItem {...legend} />
              ))}
            </SeatLegend>
            <SeatView ratio={ratio} isMin={isMinimal}>
              <SeatArrayS ratio={ratio} isMin={isMinimal}>
                {lists?.map((seat) => (
                  <React.Fragment key={seat.id}>
                    <MapViewSeat
                      ratio={ratio}
                      isMin={isMinimal}
                      used="#222"
                      top={seat.pos_top}
                      left={seat.pos_left}
                      onClick={() => {
                        if (seat.status === 'block' || seat.status === 1) return blockModal(seat);
                        if (seat.status === 'idle') return blockModal(seat);
                        showModal(seat);
                        // blockModal(seat);
                      }}
                      {...seat}>
                      {selectedMode === 'seatNumber' && (
                        <>
                          <SeatLevel
                            ratio={ratio}
                            isMinimalized={isMinimal}
                            level={seat.level}
                            inverted
                          />
                          <div className="seatNumber">{seat.number}</div>
                        </>
                      )}
                      {selectedMode === 'seatInfoAndRemainedTime' && (
                        <div className="seatInfoAndRemainedTime">
                          {getSeatTypeString(seat)}
                          {getSeatTicketTypeString(seat)}
                          {getSeatRemainUsingTime(seat)}
                          {getSeatRemainRestTime(seat)}
                        </div>
                      )}
                      {selectedMode === 'userInfo' && (
                        <div
                          className="userInfo"
                          dangerouslySetInnerHTML={{
                            __html: `${seat.userName ? `${seat.userName}<br />` : ''}${
                              seat.phoneNum
                                ? Format.formatPhone(seat.phoneNum).split('-').join('<br />')
                                : '사용중인<br />회원 없음'
                            }`,
                          }}
                        />
                      )}
                    </MapViewSeat>

                    {/* {(seat.status === 'block' || seat.status === 1) && (
                      <Seat
                        ratio={ratio}
                        isMin={isMinimal}
                        used="#222"
                        top={seat.pos_top}
                        left={seat.pos_left}
                        onClick={() => blockModal(seat)}
                        {...seat}>
                        <p>{seat.number}</p>
                        {level && (
                          <>
                            <p>{seat.level}등급</p>
                            <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                          </>
                        )}
                      </Seat>
                    )} */}
                    {/* {seat.status === 'idle' && (
                      <Seat
                        ratio={ratio}
                        isMin={isMinimal}
                        className="seat"
                        top={seat.pos_top}
                        left={seat.pos_left}
                        onClick={() => blockModal(seat)}
                        {...seat}>
                        <p>{seat.number}</p>
                        {level && (
                          <>
                            <p>{seat.level}등급</p>{' '}
                            <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                          </>
                        )}
                      </Seat>
                    )} */}
                    {/* 세미나실 배열 - 220726기준 좌석과 함께 출력중*/}
                    {/* {seminarArr?.map(
                      (li) =>
                        li.seminarId === seat.id && (
                          <Seat
                            key={li.id}
                            ratio={ratio}
                            isMin={isMinimal}
                            used="#616161"
                            top={seat.pos_top}
                            left={seat.pos_left}
                            onClick={() => showModal(li)}
                            {...seat}>
                            <p>{seat.number}</p>
                            {level && (
                              <>
                                <p>등급 : {seat.level}</p>{' '}
                                <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                              </>
                            )}
                            <HoverInfo show={toggle}>
                              <p>{li.phoneNum}</p>
                            </HoverInfo>
                          </Seat>
                        ),
                    )} */}
                    {/* 현재사용중인 이용권-일회권 */}
                    {/* {seat.status === 'using' && seat.seatType === 1 && (
                      <Seat
                        ratio={ratio}
                        isMin={isMinimal}
                        className="seat"
                        used="#1BBABC"
                        top={seat.pos_top}
                        left={seat.pos_left}
                        onClick={() => showModal(seat)}
                        {...seat}>
                        <p>{seat.number}</p>
                        {level && (
                          <>
                            <p>{seat.level}등급</p>{' '}
                            <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                          </>
                        )}
                        <HoverInfo show={toggle} used="#1BBABC">
                          <p>{seat.phoneNum}</p>
                          <p>
                            {secondToHour(diffTime1(seat.lastIn, seat.remainTime))}
                            남음
                          </p>
                        </HoverInfo>
                      </Seat>
                    )} */}
                    {/* 사용중인 좌석 - 세미나실 제외,자유석 */}
                    {/* {seat.status === 'using' && seat.ticketType !== 4 && seat.seatType === 0 && (
                      <Seat
                        ratio={ratio}
                        isMin={isMinimal}
                        className="seat"
                        top={seat.pos_top}
                        left={seat.pos_left}
                        used={
                          seat.ticketType === 1
                            ? '#616161'
                            : seat.ticketType === 2
                            ? '#FF8F00'
                            : '#00838F'
                        }
                        onClick={() => showModal(seat)}
                        {...seat}>
                        <p>{seat.number}</p>
                        {level && (
                          <>
                            <p>{seat.level}등급</p>{' '}
                            <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                          </>
                        )}
                        <HoverInfo
                          isMin={isMinimal}
                          show={toggle}
                          used={
                            seat.ticketType === 1 || seat.ticketType === 4
                              ? '#616161'
                              : seat.ticketType === 2
                              ? '#FF8F00'
                              : '#00838F'
                          }>
                          <p>{seat.phoneNum}</p>
                          {seat.ticketType === 1 && <p>{diffTime(seat.endDate)} 남음</p>}
                          {(seat.ticketType === 2 || seat.ticketType === 3) && (
                            <p>
                              {secondToHour(diffTime1(seat.lastIn, seat.remainTime))}
                              남음
                            </p>
                          )}
                        </HoverInfo>
                      </Seat>
                    )} */}
                    {/* 휴식중인 좌석 - 고정석 */}
                    {/* {seat.status === 'rest' && seat.seatType === 1 && (
                      <Seat
                        ratio={ratio}
                        isMin={isMinimal}
                        className="seat"
                        top={seat.pos_top}
                        left={seat.pos_left}
                        onClick={() => showModal(seat)}
                        used="#7bb4ff"
                        {...seat}>
                        <p>{seat.number}</p>
                        {level && (
                          <>
                            <p>{seat.level}등급</p>{' '}
                            <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                          </>
                        )}
                        <HoverInfo show={toggle} used="#7bb4ff">
                          <p>{seat.phoneNum}</p>
                          <p>{diffTimeToRest(seat.updatedAt)} 지남</p>
                        </HoverInfo>
                      </Seat>
                    )} */}
                    {/* 휴식중인 좌석 - 자유석 */}
                    {/* {seat.status === 'rest' && seat.seatType === 0 && (
                      <Seat
                        ratio={ratio}
                        isMin={isMinimal}
                        className="seat"
                        top={seat.pos_top}
                        left={seat.pos_left}
                        onClick={() => showModal(seat)}
                        used={
                          seat.ticketType === 1
                            ? '#B0BEC5'
                            : seat.ticketType === 2
                            ? '#FFAB91'
                            : '#80DEEA'
                        }
                        {...seat}>
                        <p>{seat.number}</p>
                        {level && (
                          <>
                            <p>{seat.level}등급</p>{' '}
                            <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                          </>
                        )}
                        <HoverInfo
                          show={toggle}
                          used={
                            seat.ticketType === 1
                              ? '#B0BEC5'
                              : seat.ticketType === 2
                              ? '#FFAB91'
                              : '#80DEEA'
                          }>
                          <p>{seat.phoneNum}</p>
                          <p>{diffTimeToRest(seat.updatedAt)} 지남</p>
                        </HoverInfo>
                      </Seat>
                    )} */}
                    {/* {seat.status === 'using' && seat.type === 'seminar' && (
                      <Seat
                        ratio={ratio}
                        isMin={isMinimal}
                        used="#616161"
                        top={seat.pos_top}
                        left={seat.pos_left}
                        onClick={() => showModal(seat)}
                        {...seat}>
                        <p>{seat.number}</p>
                        {level && (
                          <>
                            <p>{seat.level}등급</p>{' '}
                            <p>{seat.seatType === 0 ? '자유석' : '고정석'}</p>
                          </>
                        )}
                        <HoverInfo show={toggle}>
                          <p>{seat.phoneNum}</p>
                        </HoverInfo>
                      </Seat>
                    )} */}
                  </React.Fragment>
                ))}
                {/* 가벽 */}
                {walls?.map((wall) => (
                  <React.Fragment key={wall.id}>
                    <Wall
                      ratio={ratio}
                      isMin={isMinimal}
                      width={wall.pos_width}
                      height={wall.pos_height}
                      top={wall.pos_top}
                      left={wall.pos_left}
                    />
                  </React.Fragment>
                ))}
                {/* 글씨 */}
                {texts?.map((text) => (
                  <React.Fragment key={text.id}>
                    <Text
                      ratio={ratio}
                      isMin={isMinimal}
                      size={text.font_size}
                      top={text.pos_top}
                      left={text.pos_left}>
                      {text?.name}
                    </Text>
                  </React.Fragment>
                ))}
              </SeatArrayS>
            </SeatView>
          </>
        ) : (
          <>
            <HorizontalContentArea>
              사용자 정보 보기
              <ToggleBtn>
                <input
                  type="checkbox"
                  value={toggle || ''}
                  checked={toggle}
                  onChange={() => setToggle(!toggle)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </HorizontalContentArea>
            {multiNum && (
              <HorizontalContentArea>
                {[...Array(multiNum)].map((item, index) => (
                  <TabButton
                    selected={floorNum === index + 1}
                    onClick={() => setFloorNum(index + 1)}
                    value={index + 1}>
                    {index + 1}층
                  </TabButton>
                ))}
              </HorizontalContentArea>
            )}
            <SeatLegend>
              {LockerLegendData.map((legend) => (
                <SeatLegendItem {...legend} />
              ))}
            </SeatLegend>

            {usingLockerV2 ? (
              <LockerViewV2>
                <LockersArray>
                  {lockerList?.map((locker, index) => (
                    <React.Fragment key={locker.id}>
                      <MapViewLocker
                        ratio={ratio * 1.15}
                        isMin={isMinimal}
                        top={locker.pos_top || Math.floor(index / 20) * 48}
                        left={locker.pos_left || (index % 20) * 48}
                        isUse={locker.status === 'using'}
                        blocked={locker.status === 'block'}
                        warning={locker.endDate && diffTimeToLocker(locker.endDate) < 3}
                        toggle={toggle}
                        onClick={() => handleSelectLocker(locker)}>
                        {toggle ? (
                          <>
                            {locker.status === 'using' ? (
                              <>
                                {locker?.phoneNum}
                                <br />
                                {`${diffTimeToLocker(locker.endDate)}일`}
                                <br />
                                남음
                              </>
                            ) : (
                              <>
                                사용자
                                <br />
                                없음
                              </>
                            )}
                          </>
                        ) : (
                          locker.number
                        )}
                      </MapViewLocker>

                      {/* </Locker> */}
                      {/* 사물함 차단 */}
                      {/* {locker.status === 'block' && (
                      <LockerS isUse style={{ backgroundColor: '#979797' }}>
                        <p>
                          {locker.number}.{locker.name}
                        </p>
                      </LockerS>
                    )} */}
                      {/* 사물함 비어있음 */}
                      {/* {locker.status === 'idle' && (
                      <LockerS>
                        <p>
                          {locker.number}.{locker.name}
                        </p>
                      </LockerS>
                    )} */}

                      {/* 사물함 사용중 */}
                      {/* {locker.status === 'using' && +diffTimeToLocker(locker.endDate) > 2 && (
                      <LockerS1 onClick={() => handleSelectLocker(locker)} isUse>
                        <p>
                          {locker.number}.{locker.name}
                        </p>
                        <HoverInfo show={toggle} used="#888ea5">
                          <p>{locker?.phoneNum}</p>
                          <p>
                            {`${diffTimeToLocker(locker.endDate)}일
                                          남음`}
                          </p>
                        </HoverInfo>
                      </LockerS1>
                    )} */}
                      {/* 사물함 사용중(기간 2일 이하로 남았을경우 */}
                      {/* {locker.status === 'using' && +diffTimeToLocker(locker.endDate) < 3 && (
                      <LockerS1
                        style={{ backgroundColor: '#ca3b3b' }}
                        onClick={() => handleSelectLocker(locker)}
                        isUse>
                        <p>
                          {locker.number}.{locker.name}
                        </p>
                        <HoverInfo show={toggle} used="#ca3b3b">
                          <p>{locker?.phoneNum}</p>
                          <p>
                            {`${diffTimeToLocker(locker.endDate)}일
                                          남음`}
                          </p>
                        </HoverInfo>
                      </LockerS1>
                    )} */}
                    </React.Fragment>
                  ))}
                </LockersArray>
              </LockerViewV2>
            ) : (
              <LockerViewV1>
                {lockerList.length === 0 && (
                  <NotFoundContainer text="사물함이 없습니다." marginTop="200" marginBottom="200" />
                )}
                {lockerList?.map((locker) => (
                  <React.Fragment key={locker.id}>
                    <MapViewLockerV1
                      isUse={locker.status === 'using'}
                      blocked={locker.status === 'block'}
                      warning={locker.endDate && diffTimeToLocker(locker.endDate) < 3}
                      toggle={toggle}
                      onClick={() => handleSelectLocker(locker)}>
                      {toggle ? (
                        <>
                          {locker.status === 'using' ? (
                            <>
                              {locker?.phoneNum}
                              <br />
                              {`${diffTimeToLocker(locker.endDate)}일`}
                              <br />
                              남음
                            </>
                          ) : (
                            <>
                              사용자
                              <br />
                              없음
                            </>
                          )}
                        </>
                      ) : (
                        locker.number
                      )}
                    </MapViewLockerV1>
                  </React.Fragment>
                ))}
              </LockerViewV1>
            )}
          </>
        )}
      </PageContainer>
    </Fragment>
  );
};

export default SeatListInfo;
