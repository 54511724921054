import { auth, authWithAdminToken, authWithRefreshToken, authWithUserToken } from '.';
import Token, { removeToken, setToken } from '../../../util/token';

const token = new Token();

/**
 * 토큰 재발급
 * @param tokenType
 * @returns
 */
export const patchRefresh = async (tokenType: RefreshTokenKey) => {
  const res = await authWithRefreshToken.patch(
    '/refresh',
    undefined,
    token.getConfigWithRefreshToken(tokenType),
  );

  if (res.status === 200) {
    const newAccessToken = res.data.accessToken;
    if (tokenType === 'adminRefreshToken') setToken('adminToken', newAccessToken);
    if (tokenType === 'merchantRefreshToken') setToken('merchantToken', newAccessToken);
    if (tokenType === 'userRefreshToken') setToken('userToken', newAccessToken);
    console.log('토큰 재발급됨', tokenType);
  }
  return res;
};

/**
 * 최고 관리자 지점 전환
 * @param merchantId
 * @returns
 */
export const postAdminMerchant = async (merchantId: number) => {
  const res = await authWithAdminToken.post('/admin/merchant', { merchantId });
  const { accessToken, refreshToken } = res.data;
  setToken('merchantToken', accessToken);
  setToken('merchantRefreshToken', refreshToken);
  localStorage.setItem('level', 'merchant');
  window.location.href = '/admin/dashboard';
  return res;
};

/**
 * 사용자 지점 전환
 * @param merchantId
 * @returns
 */
export const postUserMerchant = async (merchantId: number) => {
  const res = await authWithUserToken.post('/user/merchant', { merchantId });
  const { loginType, accessToken, refreshToken } = res.data;
  setToken('merchantToken', accessToken);
  setToken('merchantRefreshToken', refreshToken);
  localStorage.setItem('level', 'merchant');
  window.location.href = '/dashboard';
  return res;
};

/**
 * 사용자 로그인
 */
export const postUserLogin = async (phoneNum: string, password: string) => {
  const res = await auth.post(`/user/login`, { phoneNum, password });

  const { accessToken, refreshToken, loginType } = res.data;
  if (loginType === 'login-user') {
    setToken('userToken', accessToken);
    setToken('userRefreshToken', refreshToken);
    localStorage.setItem('level', 'user');
    window.location.href = '/select-merchant';
    return res;
  }

  setToken('merchantToken', accessToken);
  setToken('merchantRefreshToken', refreshToken);
  localStorage.setItem('level', 'merchant');
  window.location.href = '/dashboard';
  return res;
};

/**
 * 지점 관리자 및 최고 관리자 로그인
 */
export const postMerchantLogin = async (merchantCode: string, password: string) => {
  const res = await auth.post(`/merchant/login`, { merchantCode, password });
  if (res.status === 200) {
    // loginType: admin | merchant
    const { loginType, accessToken, refreshToken } = res.data;
    if (loginType === 'admin') {
      setToken('adminToken', accessToken);
      setToken('adminRefreshToken', refreshToken);
      localStorage.setItem('level', 'admin');
      window.location.href = `${process.env.PUBLIC_URL}/merchant-list`;
    }
    if (loginType === 'merchant') {
      setToken('merchantToken', accessToken);
      setToken('merchantRefreshToken', refreshToken);
      localStorage.setItem('level', 'merchant');
      window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard`;
    }
  }
  return res;
};

/**
 * 사용자 로그아웃
 * @returns
 */
export const deleteLogout = async () => {
  try {
    await authWithRefreshToken.delete(`/logout`);
  } catch (error) {
  } finally {
    localStorage.clear();
    removeToken();
    window.location.href = '/auth/login';
  }
};
